html,body,#root { 
    margin:0; 
    padding:0; 
    height:100%; 
    width:100%; 
    display: flex; 
    justify-content: center; 
    font-family: 'Open Sans', sans-serif;
}

html {
    background: #F6F6F6;
}

#root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

@media only screen and (max-width: 200px) {
    #main {
        font-size: 12px;
        max-width: 600px;
        display: flex;
        justify-content: center;
    }
}

@media only screen and (min-width: 400px) {
    #main {
        font-size: 14px;
        max-width: 600px;
        display: flex;
        justify-content: center;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 400px) {
    #main {
        font-size: 16px;
        max-width: 600px;
        display: flex;
        justify-content: center;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    #main {
        font-size: 18px;
        max-width: 600px;
        display: flex;
        justify-content: center;
    }
}

::-webkit-scrollbar{
    width: 4px;
    height: 4px;
}
::-webkit-scrollbar-thumb{
    background: #00a595;
    border: 6.8px none #F50808;
    border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover{
    background: #7296a7;
}
::-webkit-scrollbar-track{
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: inset 10px 10px 30px -11px #E3E3E3;
}

.MuiCheckbox-colorPrimary.Mui-checked {
    color: #FF4500!important;
}